<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    components: {
        Layout,
    },

    computed: {
        ...mapGetters({
            items: 'sms/out',
            pager: 'sms/outPager',
            itemsLoading: 'sms/loading'
        }),
        rows: function () {
            return this.items && this.items.length
        },
        fields: function () {
            return [
                {
                    key: "create_date",
                    label: this.$t('sms.date'),
                    thClass: 'gridjs-th',
                    formatter: (value) => {
                        return moment(value).format('YYYY. MM. DD. HH:mm')
                    },
                    sortable: true
                },
                {
                    key: "status",
                    label: this.$t('sms.status'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "message",
                    label: this.$t('sms.message'),
                    thClass: 'gridjs-th border-left-0',
                    formatter: (value) => value?.truncate(50),
                    sortable: true
                },
                {
                    key: "number",
                    label: this.$t('sms.phone'),
                    thClass: 'gridjs-th',
                    formatter: (value) => value.phone(),
                    sortable: true
                },
                {
                    key: "sum_view",
                    label: this.$t('sms.views'),
                    thClass: 'gridjs-th',
                    tdClass: 'text-end',
                    sortable: true
                },
                {
                    key: "unique_view",
                    label: this.$t('sms.viewers'),
                    thClass: 'gridjs-th',
                    tdClass: 'text-end',
                    sortable: true
                },
                {
                    key: "price",
                    label: this.$t('sms.net_price'),
                    thClass: 'gridjs-th',
                    tdClass: 'text-end',
                    formatter: (value) => this.formatter.format(value),
                    sortable: true
                },
            ];
        },
    },
    data() {
        return {
            page: (this.pager && this.pager.current_page) || 1,
            perPage: (this.pager && this.pager.limit) || 10,
            currentPage: (this.pager && this.pager.current_page) || 1,
            sortBy: (this.pager && this.pager.column) || 'create_date',
            sortDesc: (this.pager && this.pager.desc) || true,
            formatter: new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumFractionDigits: 1
            })
        };
    },
    methods: {
        toPage: function (val) {
            this.page = val
            this.delayedFetch()
        },
        delayedFetch: function () {
            this.$store.dispatch('sms/fetchOutDelayed', {
                limit: this.perPage,
                page: this.page,
                sorting: {
                    column: this.sortBy,
                    desc: this.sortDesc
                }
            });
        },
    },
    watch: {
        sortBy: function () {
            this.delayedFetch();
        },
        sortDesc: function () {
            this.delayedFetch();
        },
    },
    created() {
        document.title = this.setTitle(this.$t('sms.sms_sent'));
        this.delayedFetch();
    }
};
</script>

<template>
    <Layout>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('sms.sms_sent') }}</h4>
            </div>
            <div class="card-body p-0 pb-3 p-md-3">
                <!-- Table -->
                <div class="table-responsive mh-70-vh mh-md-auto">
                    <div class="mb-0">
                        <b-table
                            :items="items"
                            :fields="fields"
                            responsive="sm"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :busy="itemsLoading"
                            hover
                            head-variant="light"
                            no-local-sorting
                        >
                            <template #table-busy>
                                <div class="d-flex justify-content-center">
                                    <b-spinner></b-spinner>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex w-100 align-items-center justify-content-center justify-content-md-end">
                            <div
                                class="dataTables_paginate paging_simple_numbers"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                        v-model="page"
                                        :total-rows="pager.count"
                                        :per-page="perPage"
                                        @change="toPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
